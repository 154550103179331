import { request } from '@/api/request'
import VueCookies from 'vue-cookies'
import OSS from 'ali-oss'
import { v4 as uuidv4 } from 'uuid'
import { getFileExtension } from '@/utils/utils'
import { httpUrlReg } from '@/utils/regulex'
import { message } from 'element-ui'
import config from '@/config'

// 获取OSS临时访问凭证，保存到cookie，过期后重新获取
export const getOssAccessCertAPI = async (data) => {
    let ossAccessCert = VueCookies.get('ossAccessCert')
    if (ossAccessCert) {
        return ossAccessCert
    }
    const getOssRes = await request({
        url: '/sys/aliOss/getOssAccessCert',
        method: 'POST',
        data
    })
    if (getOssRes.code != 200 && !getOssRes.data) {
        return false
    }
    ossAccessCert = getOssRes.data
    VueCookies.set('ossAccessCert', ossAccessCert, new Date(ossAccessCert.expiration))
    return ossAccessCert
}

const ossClient = async ({ region = '', bucket = '' } = {}) => {
    const ossAccessCert = await getOssAccessCertAPI()

    // 初始化oss
    return new OSS({
        accessKeyId: ossAccessCert.accessKeyId,
        accessKeySecret: ossAccessCert.accessKeySecret,
        stsToken: ossAccessCert.securityToken,
        region,
        bucket,
        secure: true
    })
}

const setFilePath = ({
    directory = '', // 文件路径
    fileNameType = 1, // 上传文件命名类型，1：随机文件名，2：原文件名，3：自定义文件名
    fileName = '',
    file = null
} = {}) => {
    if (!file) {
        message.error('未选择上传文件')
        return false
    }
    if (!directory) {
        message.error('上传出错，请联系客服')
        return false
    }

    if (config.NODE_ENV === 'dev' || config.NODE_ENV === 'test') {
        // 方便清除测试文件
        directory = `${config.NODE_ENV}/${directory}`
    }

    let filePath = '' // 上传到oss的object名，如：example/example.txt
    if (fileNameType === 1) {
        filePath = `${directory}/${uuidv4().replace(/-/g, '')}.${getFileExtension(file.name).toLocaleLowerCase()}`
    } else if (fileNameType === 2) {
        filePath = `${directory}/${file.name.toLocaleLowerCase()}`
    } else if (fileNameType === 3) {
        filePath = `${directory}/${fileName}.${getFileExtension(file.name).toLocaleLowerCase()}`
    }
    return filePath
}

// 简单上传
export const basicUploadFile = async ({ directory = '', fileNameType = 1, fileName = '', file = null, region = 'oss-cn-shenzhen', bucket = 'read-public-resources' } = {}) => {
    const filePath = setFilePath({ directory, fileNameType, fileName, file })
    if (!filePath) return false

    const client = await ossClient({ region, bucket })

    try {
        return await client.put(filePath, file)
    } catch (err) {
        return err
    }
}

// 分片上传
export const multipartUploadFile = async ({
    directory = '',
    fileNameType = 1,
    fileName = '',
    file = null,
    region = 'oss-cn-shenzhen',
    bucket = 'read-public-resources',
    parallel = 5, // 并发上传的分片数量
    partSize = 1024 * 100, // 分片大小。默认值为100KB，最小值为100 KB
    onProgress = () => {},
    onSuccess = () => {},
    onError = () => {}
} = {}) => {
    const filePath = setFilePath({ directory, fileNameType, fileName, file })
    if (!filePath) return false

    const client = await ossClient({ region, bucket })

    await client
        .multipartUpload(filePath, file, {
            parallel,
            partSize,
            progress: (p, cpt, res) => {
                // 获取分片上传进度、断点和返回值
                onProgress(p, cpt, res)
            }
        })
        .then((res) => {
            res.requestUrl = `https://${bucket}.${region}.aliyuncs.com/${filePath}`
            onSuccess(res)
        })
        .catch((err) => {
            onError(err)
        })
}

// 删除单个文件
export const deleteSingleFile = async ({ url = '' } = {}) => {
    const { bucket, region, object } = splitOssUrl(url)
    const client = await ossClient({ region, bucket })

    try {
        const delRes = await client.delete(object)
        return delRes.res
    } catch (err) {
        return err
    }
}

/**
 * 批量删除文件
 * 注：仅支持在同一bucket和region的批量删除
 * @param {Array} urls 指定多个待删除Object的名称。Object名称需填写不包含Bucket名称在内的Object的完整路径
 * @param {Boolean} quiet 通过quiet参数指定是否返回所有删除的文件列表。当quiet指定为true时，OSS不返回消息体。当quiet指定为false时，OSS返回所有删除的文件列表
 * @returns
 */
export const deleteMultiFile = async ({ urls = [], quiet = false } = {}) => {
    let bucket,
        region,
        objects = []
    urls.forEach((item, index) => {
        const result = splitOssUrl(item)
        if (index === 0) {
            bucket = result.bucket
            region = result.region
        }
        objects.push(result.object)
    })
    const client = await ossClient({ region, bucket })

    try {
        const delRes = await client.deleteMulti(objects, { quiet })
        return delRes.res
    } catch (err) {
        return err
    }
}

// 获取生成签名URL
export const getSignatureUrl = async ({ url = '', expires = 3600, method = 'GET', process = '' } = {}) => {
    if (!httpUrlReg.test(url)) {
        // 非 http(s) 开头，直接返回原数据
        return url
    }

    const { bucket, region, object } = splitOssUrl(url)
    const client = await ossClient({ region, bucket })

    const signatureUrl = client.signatureUrl(object, { expires, method, process })
    return signatureUrl
}

// oss 地址分离
export const splitOssUrl = (url) => {
    url = decodeURIComponent(url)
    url = url.replace(/^https?:\/\//, '')
    const origin = url.substring(0, url.indexOf('/')) // oss-cn-shenzhen.aliyuncs.com
    const object = url.substring(url.indexOf('/')) // /example.jpg
    const bucket = origin.split('.')[0]
    const region = origin.split('.')[1]
    const extension = object.split('.')[object.split('.').length - 1] // .jpg
    return { bucket, region, object, extension }
}
