//引入提示组件
import { message } from 'element-ui'
//引入当前环境的接口域名
import config from '@/config'
// 生成随机字符串
import { v4 as uuidv4 } from 'uuid'
// 引入MD5加密
import { MD5 } from 'crypto-js'
// vue-cookies配置
import VueCookies from 'vue-cookies'

var websock = null
var global_callback = null
let tryTime = 0

// 心跳包内容
var heartBeat = {
    messageType: 0
}
var heartInterval
function heartBeatInterval() {
    heartInterval = setInterval(() => {
        if (websock.readyState === websock.OPEN) {
            websock.send(JSON.stringify(heartBeat))
        }
    }, 60 * 1000)
}

function createWebSocket(callback) {
    if (websock == null || typeof websock !== WebSocket) {
        initWebSocket(callback)
    }
}

function initWebSocket(callback) {
    global_callback = callback
    let ts = Date.parse(new Date()) + Number(VueCookies.get('timeDifference'))
    let ns = uuidv4()
    ns = ns.replace(/-/g, '')
    let tk = VueCookies.get('token') ? VueCookies.get('token') : ''
    let n = Number(ts % 32)
    const sg = MD5(ns.substring(n, n + 1) + ns + ns.substring(32 - n - 1, 32 - n) + ts + ns.substring(32 - n - 1, 32 - n) + tk + ns.substring(n, n + 1)).toString()
    // ws请求完整地址
    const requstWsUrl = `${config.wsUrl}/sysuser/${tk}/${ts}/${ns}/${sg}`
    // 初始化websocket
    websock = new WebSocket(requstWsUrl)
    websock.onmessage = function (e) {
        websocketonmessage(e)
    }
    websock.onclose = function (e) {
        websock = null
        websocketclose(e)
    }
    websock.onopen = function () {
        websocketOpen()
    }
    // 连接发生错误的回调方法
    websock.onerror = function (e) {
        websock = null
        //createWebSocket();啊，发现这样写会创建多个连接，加延时也不行
    }
    // 定时发送心跳包，1分钟发送一次
    heartBeatInterval()
}

// 实际调用的方法
function sendSock(agentData) {
    if (websock.readyState === websock.OPEN) {
        // 若是ws开启状态
        websocketsend(agentData)
    } else if (websock.readyState === websock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData)
        }, 1000)
    } else {
        // 若未开启 ，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData)
        }, 1000)
    }
}

function closeSock(type) {
    if (type == 'logout') {
    } else {
        websock.close()
    }
}

// 数据接收
function websocketonmessage(msg) {
    // global_callback(JSON.parse(msg.data));
    // 收到信息为Blob类型时
    let result = null
    if (msg.data instanceof Blob) {
        const reader = new FileReader()
        reader.readAsText(msg.data, 'UTF-8')
        reader.onload = (e) => {
            result = JSON.parse(reader.result)
            global_callback(result)
        }
    } else {
        result = JSON.parse(msg.data)
        global_callback(result)
    }
}

// 数据发送
function websocketsend(agentData) {
    websock.send(agentData)
}

// 关闭
function websocketclose(e) {
    clearInterval(heartInterval)
    if (e && e.code !== 1000) {
        // message({type: "error",message: '重连失败！请稍后重试'})
        // errorCallback()
        // 如果需要设置异常重连则可替换为下面的代码，自行进行测试
        if (tryTime < 10) {
            setTimeout(function () {
                tryTime++
                initWebSocket(global_callback)
            }, 3 * 1000)
        } else {
            // websock = null
            message({ type: 'error', message: '重连失败！请稍后重试' })
        }
    }
}

function websocketOpen(e) {
    tryTime = 0
}

export { sendSock, createWebSocket, closeSock, websock }
