import { request } from "./request";

// 获取系统用户列表
function selectSystemUserListPage(data) {
    return request({
        url: '/sys/user/selectListPage',
        method: 'POST',
        data: data
    })
}
// 系统用户编辑/保存
function systemUserAccountSave(data) {
    return request({
        url: '/sys/user/userSave',
        method: 'POST',
        data: data
    })
}
// 获取整个组织架构树列表
function getAllDeptTreeList(data) {
    return request({
        url: '/sys/dept/getAllDeptTreeList',
        method: 'POST',
        data: data
    })
}
// 获取系统角色分页列表
function selectSystemRoleListPage(data) {
    return request({
        url: '/sys/role/selectListPage',
        method: 'POST',
        data: data
    })
}
// 系统角色新增/修改
function systemRoleSave(data) {
    return request({
        url: '/sys/role/roleSave',
        method: 'POST',
        data: data
    })
}
// 获取系统角色信息
function getRoleData(data) {
    return request({
        url: '/sys/role/getRole',
        method: 'POST',
        data: data
    })
}
// 查找系统用户信息
function getSystemAccountData(data) {
    return request({
        url: '/sys/user/getUser',
        method: 'POST',
        data: data
    })
}
// 添加/修改部门节点
function systemDeptSave(data) {
    return request({
        url: '/sys/dept/deptSave',
        method: 'POST',
        data: data
    })
}
// 获取部门节点详情数据
function getSystemDeptData(data) {
    return request({
        url: '/sys/dept/getDept',
        method: 'POST',
        data: data
    })
}
// 删除部门节点
function systemDeptDelete(data) {
    return request({
        url: '/sys/dept/deptDelete',
        method: 'POST',
        data: data
    })
}
// 获取区域列表
function selectRegionListPage(data) {
    return request({
        url: '/sys/region/selectListPage',
        method: 'POST',
        data: data
    })
}
// 获取区域列表
function systemRegionSave(data) {
    return request({
        url: '/sys/region/regionSave',
        method: 'POST',
        data: data
    })
}
// 获取区域详情数据
function getSystemRegionData(data) {
    return request({
        url: '/sys/region/getRegion',
        method: 'POST',
        data: data
    })
}
// 获取菜单树状列表（包括按钮）
function getAllMenuTreeList(data) {
    return request({
        url: '/sys/menu/getAllMenuTreeList',
        method: 'POST',
        data: data
    })
}
// 获取菜单树状列表（不包括按钮）
function getMenuTreeList(data) {
    return request({
        url: '/sys/menu/getMenuTreeList',
        method: 'POST',
        data: data
    })
}
// 新增/修改菜单
function systemMenuSave(data) {
    return request({
        url: '/sys/menu/menuSave',
        method: 'POST',
        data: data
    })
}
// 删除菜单节点
function systemMenuDelete(data) {
    return request({
        url: '/sys/menu/menuDelete',
        method: 'POST',
        data: data
    })
}
// 获取菜单节点详情数据
function getSystemMenuData(data) {
    return request({
        url: '/sys/menu/getMenu',
        method: 'POST',
        data: data
    })
}
// 获取菜单功能列表
function selectMenuFunctionListPage(data) {
    return request({
        url: '/sys/menuFunction/selectListPage',
        method: 'POST',
        data: data
    })
}
// 新增/修改菜单功能
function systemMenuFunctionSave(data) {
    return request({
        url: '/sys/menuFunction/menuFunctionSave',
        method: 'POST',
        data: data
    })
}
// 删除菜单功能
function systemMenuFunctionDelete(data) {
    return request({
        url: '/sys/menuFunction/menuFunctionDelete',
        method: 'POST',
        data: data
    })
}
// 获取日志列表
function selectSysOperateListPage(data) {
    return request({
        url: '/sys/sysOperateLog/selectListPage',
        method: 'POST',
        data: data
    })
}
// 获取当前用户的权限菜单树和菜单功能列表信息
function getCurrentUserMenuInfo(data) {
    return request({
        url: '/sys/menu/getCurrentUserMenuInfo',
        method: 'POST',
        data: data
    })
}
// 获取系统信息
function getSysConfigAPI(data) {
    return request({
        url: '/sys/sysConfig/getSysConfig',
        method: 'POST',
        data: data
    })
}
// 保存系统信息
function sysConfigSaveAPI(data) {
    return request({
        url: '/sys/sysConfig/sysConfigSave',
        method: 'POST',
        data: data
    })
}
export default {
    selectSystemUserListPage,
    systemUserAccountSave,
    getAllDeptTreeList,
    selectSystemRoleListPage,
    systemRoleSave,
    getRoleData,
    getSystemAccountData,
    systemDeptSave,
    getSystemDeptData,
    systemDeptDelete,
    selectRegionListPage,
    systemRegionSave,
    getSystemRegionData,
    getAllMenuTreeList,
    getMenuTreeList,
    systemMenuSave,
    systemMenuDelete,
    getSystemMenuData,
    selectMenuFunctionListPage,
    systemMenuFunctionSave,
    systemMenuFunctionDelete,
    selectSysOperateListPage,
    getCurrentUserMenuInfo,
    getSysConfigAPI,
    sysConfigSaveAPI,
}