import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import LemonIMUI from 'lemon-imui'
import 'lemon-imui/dist/index.css'
Vue.use(LemonIMUI)

// 引入剪切板
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//导入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

//引入element-ui
import '@/element/index.js'

//引入网络请求的方法
import api from '@/api/index'
Vue.prototype.$api = api

//引入图片裁剪框组件
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

//导入活动字体图标文件
import '@/assets/act_iconfont/iconfont.css';
//导入字体图标文件
import '@/assets/iconfont/iconfont.css'

// 引入公共样式
import '@/assets/css/base.less'

// 引入富文本插件样式
import '@wangeditor/editor/dist/css/style.css'

// 引入动画特效
import AOS from 'aos'

import '../node_modules/aos/dist/aos.css' // 文件可以复制到项目中的style目录中调用
Vue.prototype.$aos = AOS

// 引入oss方法
import { basicUploadFile } from '@/utils/ali-oss-upload'
// 简单上传
Vue.prototype.$basicUploadFile = basicUploadFile

// 引入lodash
import _ from 'lodash'
Vue.prototype._ = _ //全局导入的挂载方式

// 引入crypto
import Crypto from '@/utils/crypto.js'
Vue.prototype.$crypto = Crypto

// 引入el-select下拉框自定义下拉加载更多事件
import loadMore from '@/utils/loadMore' // 下拉加载更多
Vue.use(loadMore)

import './directive' // 引入全局指令

// vue-cookies配置
import cookies from 'vue-cookies'
Vue.prototype.$cookies = cookies

// 挂载一级列表页和二级修改页的事件总线
import goBackData from '@/utils/eventBus.js'
Vue.prototype.$goBackData = goBackData

// 指引步骤插件
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
Vue.use(VueTour)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
