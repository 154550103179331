import {request} from "./request";

// 字典项数据接口
function getDictTypeList(data) {
    return request({
        url: '/sys/dict/getDictTypeList',
        method: 'POST',
        data: data
    })
}
// 查询省市区
function selectAreaList(data) {
    return request({
        url: '/sys/sysArea/selectAreaList',
        method: 'POST',
        data: data
    })
}
// 随机字典项数据
function getDictTypeRandomList(data) {
    return request({
        url: '/sys/dict/getDictTypeRandomList',
        method: 'POST',
        data: data
    })
}
export default {
    getDictTypeList,
    selectAreaList,
    getDictTypeRandomList,
}