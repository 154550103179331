import {request} from "./request";

// 获取天气
function getWeather(data) {
  return request({
    url: '/sys/weather/getWeather',
    method: 'POST',
    data
  })
}
// 获取用户数据增长比例
function getHomeCountData(data) {
  return request({
    url: '/sys/home/getHomeCountData',
    method: 'POST',
    data
  })
}
// 获取用户数据实时统计
function getHomeHourTrend(data) {
  return request({
    url: '/sys/home/getHourTrend',
    method: 'POST',
    data
  })
}
// 获取用户数据功能使用统计
function getHomeModuleUseCount(data) {
  return request({
    url: '/sys/home/getModuleUseCount',
    method: 'POST',
    data
  })
}
// 获取用户画像分析数据
function getHomeWebUseCount(data) {
  return request({
    url: '/sys/home/getWebUseCount',
    method: 'POST',
    data
  })
}
// 获取首页活动数据
function getHomeActCount(data) {
  return request({
    url: '/sys/home/getActCount',
    method: 'POST',
    data
  })
}
// 获取活动热度排行TopN列表
function getHomeActHotTopList(data) {
  return request({
    url: '/sys/home/getActHotTopList',
    method: 'POST',
    data
  })
}
// 获取个人用户活跃排行TopN列表
function getHomeUserActEnrollCntTopList(data) {
  return request({
    url: '/sys/home/getUserActEnrollCntTopList',
    method: 'POST',
    data
  })
}
// 获取机构发布活动排行TopN列表
function getHomeOrgActCntTopList(data) {
  return request({
    url: '/sys/home/getOrgActCntTopList',
    method: 'POST',
    data
  })
}

// 获取所有未审核/未读消息统计数据
function getUnAppOrUnReadMessageCountAPI(data) {
  return request({
      url: '/sys/home/getUnAppOrUnReadMessageCount',
      method: 'POST',
      data: data
  })
}

// AI交互
// AI文本生成图片
function wordsToImageAPI(data) {
  return request({
    url: '/sys/wordsToImage/wordsToImage',
    method: 'POST',
    data
  })
}
// AI文本生成图片查询
function fetchTaskImageAPI(data) {
  return request({
    url: '/sys/wordsToImage/fetchTask',
    method: 'POST',
    data
  })
}

// 统计当前用户剩余灵感值和收藏图片数量
function countLimitAndCollectAPI(data) {
  return request({
    url: '/sys/wordsToImage/countLimitAndCollect',
    method: 'POST',
    data
  })
}

// 收藏/取消AI图片
function collectAiImageAPI(data) {
  return request({
    url: '/sys/wordsToImage/collectImage',
    method: 'POST',
    data
  })
}

// 获取AI绘图的数据
function selectWordsToImageListPage(data) {
  return request({
    url: '/sys/wordsToImage/selectListPage',
    method: 'POST',
    data
  })
}

// 获取当前用户系统指引状态
function getCurrentUserSysGuideShowLogAPI(data) {
  return request({
    url: '/sys/sysGuideShowLog/getCurrentUserSysGuideShowLog',
    method: 'POST',
    data
  })
}

// 当前用户系统指引状态保存/修改
function sysGuideShowLogSaveAPI(data) {
  return request({
    url: '/sys/sysGuideShowLog/sysGuideShowLogSave',
    method: 'POST',
    data
  })
}

// 获取当前用户每天可免费使用绘画次数
function getCurrentUserDayLimitAPI(data) {
  return request({
    url: '/sys/wordToImageUserDayLimit/getCurrentUserDayLimit',
    method: 'POST',
    data
  })
}
export default {
	getWeather,
  getHomeCountData,
  getHomeHourTrend,
  getHomeModuleUseCount,
  getHomeWebUseCount,
  getHomeActCount,
  getHomeActHotTopList,
  getHomeUserActEnrollCntTopList,
  getHomeOrgActCntTopList,
  getUnAppOrUnReadMessageCountAPI,
  wordsToImageAPI,
  fetchTaskImageAPI,
  countLimitAndCollectAPI,
  collectAiImageAPI,
  selectWordsToImageListPage,
  getCurrentUserSysGuideShowLogAPI,
  sysGuideShowLogSaveAPI,
  getCurrentUserDayLimitAPI,
}
