import {request} from "./request";

// 获取验证码
function getIdentifyCode(data) {
    return request({
        url: '/sys/login/captcha',
        method: 'GET',
        params: data
    })
}
// 工号密码登录
function loginByPwd(data) {
    return request({
        url: '/sys/login/loginByPwd',
        method: 'POST',
        data: data
    })
}
// 统一认证登录
function loginByUni(data) {
    return request({
        url: '/sys/login/ssoLogin',
        method: 'POST',
        data
    })
}
// 获取个人资料
function getUserInfo(data) {
    return request({
        url: '/sys/user/getUser',
        method: 'POST',
        data: data
    })
}
// 修改个人资料
function editUserInfo(data) {
    return request({
        url: '/sys/user/userSave',
        method: 'POST',
        data: data
    })
}
// 修改密码
function editPassword(data) {
    return request({
        url: '/sys/user/editPassword',
        method: 'POST',
        data: data
    })
}
// 退出登录
function logout(data) {
    return request({
        url: '/sys/user/logout',
        method: 'POST',
        data: data
    })
}
export default {
    getIdentifyCode,
    loginByPwd,
    loginByUni,
    getUserInfo,
    editUserInfo,
    editPassword,
    logout,
}