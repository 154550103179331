import { request } from './request'

//机构管理
// 获取一级机构列表
function selectFirstListPage(data) {
    return request({
        url: '/sys/webOrg/selectListPage',
        method: 'POST',
        data: data
    })
}
// 一级机构保存/修改
function firstOrgSave(data) {
    return request({
        url: '/sys/webOrg/webOrgSave',
        method: 'POST',
        data: data
    })
}
// 一级机构列表导出数据
function firstOrgExportData(data) {
    return request({
        url: '/sys/webOrg/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 查找一级机构信息
function getFirstOrgData(data) {
    return request({
        url: '/sys/webOrg/getWebOrg',
        method: 'POST',
        data: data
    })
}
// 获取二级机构列表
function selectSecondOrgListPage(data) {
    return request({
        url: '/sys/webSecondOrg/selectListPage',
        method: 'POST',
        data: data
    })
}
// 二级机构列表导出数据
function secondOrgExportData(data) {
    return request({
        url: '/sys/webSecondOrg/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 查找二级机构信息
function getSecondOrgData(data) {
    return request({
        url: '/sys/webSecondOrg/getWebSecondOrg',
        method: 'POST',
        data: data
    })
}
// 二级机构保存/修改
function secondOrgSave(data) {
    return request({
        url: '/sys/webSecondOrg/webSecondOrgSave',
        method: 'POST',
        data: data
    })
}

// 机构专属页
// 机构专属页分页列表
function getOrgExclusiveList(data) {
    return request({
        url: '/sys/orgExclusivePageConfig/selectListPage',
        method: 'POST',
        data: data
    })
}

// 机构专属页配置
function orgExclusiveConfigSave(data) {
    return request({
        url: '/sys/orgExclusivePageConfig/orgExclusivePageConfigSave',
        method: 'POST',
        data: data
    })
}

//根据机构ID查询页面配置
function getOrgExclusive(data) {
    return request({
        url: '/sys/orgExclusivePageConfig/getOrgExclusivePageConfigByOrgId',
        method: 'POST',
        data: data
    })
}

//根据主键ID查询页面配置
function getOrgExclusivePageConfigAPI(data) {
    return request({
        url: '/sys/orgExclusivePageConfig/getOrgExclusivePageConfig',
        method: 'POST',
        data: data
    })
}

// 个人用户账号列表
function selectUserAccountListPage(data) {
    return request({
        url: '/sys/webUser/selectListPage',
        method: 'POST',
        data: data
    })
}
// 个人用户账号新增/修改
function orgUserAccountSave(data) {
    return request({
        url: '/sys/webUser/webUserSave',
        method: 'POST',
        data: data
    })
}
// 个人用户账号导出数据
function orgUserExportData(data) {
    return request({
        url: '/sys/webUser/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 获取机构用户反馈列表
function getOrgFeedbackList(data) {
    return request({
        url: '/sys/orgFeedback/selectListPage',
        method: 'POST',
        data: data
    })
}
// 机构用户反馈保存/更新
function orgFeedbackSave(data) {
    return request({
        url: '/sys/orgFeedback/orgFeedbackSave',
        method: 'POST',
        data: data
    })
}
// 获取机构用户反馈信息
function getOrgFeedback(data) {
    return request({
        url: '/sys/orgFeedback/getOrgFeedback',
        method: 'POST',
        data: data
    })
}
// 机构用户反馈列表导出数据
function orgFeedbackExportData(data) {
    return request({
        url: '/sys/orgFeedback/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 获取机构用户登录日志分页列表
function selectOrgLoginLogListPage(data) {
    return request({
        url: '/sys/orgLoginLog/selectListPage',
        method: 'POST',
        data: data
    })
}

// 用户管理
// 获取机构账号审核列表
function selectOrgReviewListPage(data) {
    return request({
        url: '/sys/orgUserUnit/selectListPage',
        method: 'POST',
        data: data
    })
}
// 机构账号审核列表导出数据
function orgReviewExportData(data) {
    return request({
        url: '/sys/orgUserUnit/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 机构账号审核
function orgUserUnitApproval(data) {
    return request({
        url: '/sys/orgUserUnit/orgUserUnitApproval',
        method: 'POST',
        data: data
    })
}
// 获取机构账号列表
function selectOrgAccountListPage(data) {
    return request({
        url: '/sys/orgUser/selectListPage',
        method: 'POST',
        data: data
    })
}
// 机构账号列表导出数据
function orgAccountExportData(data) {
    return request({
        url: '/sys/orgUser/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 机构账号新增/修改
function orgAccountSave(data) {
    return request({
        url: '/sys/orgUser/orgUserSave',
        method: 'POST',
        data: data
    })
}
// 获取机构账号信息
function getOrgUserData(data) {
    return request({
        url: '/sys/orgUser/getOrgUser',
        method: 'POST',
        data: data
    })
}

// 获取用户绑定机构
function getOrgBindList(data) {
    return request({
        url: '/sys/orgUserUnit/selectListPage',
        method: 'POST',
        data: data
    })
}

// 停用/启用用户绑定机构
function orgUserUnitSave(data) {
    return request({
        url: '/sys/orgUserUnit/orgUserUnitSave',
        method: 'POST',
        data: data
    })
}

// 获取用户反馈列表
function getWebFeedbackList(data) {
    return request({
        url: '/sys/webFeedback/selectListPage',
        method: 'POST',
        data: data
    })
}
// 用户反馈保存/更新
function webFeedbackSave(data) {
    return request({
        url: '/sys/webFeedback/webFeedbackSave',
        method: 'POST',
        data: data
    })
}
// 获取用户反馈信息
function getWebFeedback(data) {
    return request({
        url: '/sys/webFeedback/getWebFeedback',
        method: 'POST',
        data: data
    })
}
// 用户反馈列表导出数据
function webFeedbackExportData(data) {
    return request({
        url: '/sys/webFeedback/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 获取用户举报列表
function getWebReportList(data) {
    return request({
        url: '/sys/webReport/selectListPage',
        method: 'POST',
        data: data
    })
}
// 用户举报保存/更新
function webReportSave(data) {
    return request({
        url: '/sys/webReport/webReportSave',
        method: 'POST',
        data: data
    })
}
// 获取用户举报信息
function getWebReport(data) {
    return request({
        url: '/sys/webReport/getWebReport',
        method: 'POST',
        data: data
    })
}
// 用户举报列表导出数据
function webReportExportData(data) {
    return request({
        url: '/sys/webReport/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 获取官网用户登录日志分页列表
function selectWebLoginLogListPage(data) {
    return request({
        url: '/sys/webLoginLog/selectListPage',
        method: 'POST',
        data: data
    })
}

//根据ID查询官网用户在线时长
function getUserOnlineTime(data) {
    return request({
        url: '/sys/webUserOnlineTime/getWebUserOnlineTime',
        method: 'POST',
        data: data
    })
}
//获取官网用户在线时长分页列表
function selectTimeListPage(data) {
    return request({
        url: '/sys/webUserOnlineTime/selectListPage',
        method: 'POST',
        data: data
    })
}

// 资讯动态
// 获取资讯列表
function selectNewsListPage(data) {
    return request({
        url: '/sys/news/selectListPage',
        method: 'POST',
        data: data
    })
}
// 资讯保存/修改
function newsDataSave(data) {
    return request({
        url: '/sys/news/newsSave',
        method: 'POST',
        data: data
    })
}
// 删除资讯
function deleteNews(data) {
    return request({
        url: '/sys/news/newsDelete',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 查找资讯信息
function getNewsData(data) {
    return request({
        url: '/sys/news/getNews',
        method: 'POST',
        data: data
    })
}
// 获取资讯审核列表
function selectApproveNewListPage(data) {
    return request({
        url: '/sys/news/selectApproveListPage',
        method: 'POST',
        data: data
    })
}
// 获取资讯审核列表
function newsDataApprove(data) {
    return request({
        url: '/sys/news/newsApprove',
        method: 'POST',
        data: data
    })
}

// ===== 官网首页管理 START =====
// 官网banner列表
function selectBannerList(data) {
    return request({
        url: '/sys/banner/selectListPage',
        method: 'POST',
        data: data
    })
}
// 获取banner信息
function getBanner(data) {
    return request({
        url: '/sys/banner/getBanner',
        method: 'POST',
        data: data
    })
}
// 保存banner信息
function bannerSave(data) {
    return request({
        url: '/sys/banner/bannerSave',
        method: 'POST',
        data: data
    })
}
// 友情链接列表
function selectFriendshipLinkListPage(data) {
    return request({
        url: '/sys/friendshipLink/selectListPage',
        method: 'POST',
        data: data
    })
}
// ===== 官网首页管理 END =====

// ===== 帮助中心管理 START =====
// 获取文章目录树
function selectDocDirectoryTreeList(data) {
    return request({
        url: '/sys/sysDocDirectory/tree',
        method: 'POST',
        data: data
    })
}
// 获取文章列表
function selectDocInfoListPageAPI(data) {
    return request({
        url: '/sys/sysDocInfo/selectListPage',
        method: 'POST',
        data: data
    })
}
// 新增或修改目录
function sysDocDirectorySaveAPI(data) {
    return request({
        url: '/sys/sysDocDirectory/sysDocDirectorySave',
        method: 'POST',
        data: data
    })
}
// 新增或修改文章
function sysDocInfoSaveAPI(data) {
    return request({
        url: '/sys/sysDocInfo/sysDocInfoSave',
        method: 'POST',
        data: data
    })
}
// 根据ID查询系统文档信息
function getSysDocInfoAPI(data) {
    return request({
        url: '/sys/sysDocInfo/getSysDocInfo',
        method: 'POST',
        data: data
    })
}
// 转移文章
function transferSysDocAPI(data) {
    return request({
        url: '/sys/sysDocInfo/transferSysDoc',
        method: 'POST',
        data: data
    })
}
// 复制文章
function copySysDocAPI(data) {
    return request({
        url: '/sys/sysDocInfo/copySysDocToDir',
        method: 'POST',
        data: data
    })
}
// 目录拖拽调整顺序
function dragAdjustOrderAPI(data) {
    return request({
        url: '/sys/sysDocDirectory/dragAdjustOrder',
        method: 'POST',
        data: data
    })
}
// 获取系统文档反馈记录分页列表
function selectDocFeebackListPageAPI(data) {
    return request({
        url: '/sys/sysDocFeeback/selectListPage',
        method: 'POST',
        data: data
    })
}
// ===== 帮助中心管理 END =====

// 资源管理
// 获取课程资源列表
function selectResourcesListPageAPI(data) {
    return request({
        url: '/sys/course/selectListPage',
        method: 'POST',
        data: data
    })
}

// 批量导入课程资源基本信息
function importResourcesByXlsxAPI(data) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/sys/course/importByXlsx',
        method: 'POST',
        data: data
    })
}

// 课程资源基本信息导入模板下载
function importResourcesTemplateAPI(data) {
    return request({
        url: '/sys/course/downImportTemplate',
        method: 'POST',
        data: data,
        responseType: 'blob'
    })
}

//课程资源保存(新增或修改)
function courseSaveAPI(data) {
    return request({
        url: '/sys/course/courseSave',
        method: 'POST',
        data: data
    })
}

//批量修改课程资源状态
function batchUpdateCourseStatusAPI(data) {
    return request({
        url: '/sys/course/batchUpdateCourseStatus',
        method: 'POST',
        data: data
    })
}

// 导出课程
function exportCourseDataAPI(data) {
    return request({
        url: '/sys/course/export',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

// 根据ID查询课程资源
function selectResourceDetailAPI(data) {
    return request({
        url: '/sys/course/getCourse',
        method: 'POST',
        data: data
    })
}

// 导出官网在线学习目录
function exportOnlineCourseListAPI(data) {
    return request({
        url: '/sys/course/exportOnline',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

// 官网课程资源基本信息导入模板下载
function downOnlineImportTemplateAPI(data) {
    return request({
        url: '/sys/course/downOnlineImportTemplate',
        method: 'POST',
        data: data,
        responseType: 'blob'
    })
}

// 官网批量导入课程资源基本信息
function importOnlineByXlsxAPI(data) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/sys/course/importOnlineByXlsx',
        method: 'POST',
        data: data
    })
}
export default {
    //机构管理
    selectFirstListPage,
    firstOrgSave,
    firstOrgExportData,
    getFirstOrgData,
    selectSecondOrgListPage,
    secondOrgExportData,
    getSecondOrgData,
    secondOrgSave,
    selectUserAccountListPage,
    orgUserAccountSave,
    orgUserExportData,
    getOrgFeedbackList,
    orgFeedbackSave,
    getOrgFeedback,
    orgFeedbackExportData,
    selectOrgLoginLogListPage,
    getOrgExclusiveList,
    orgExclusiveConfigSave,
    getOrgExclusive,
    getOrgExclusivePageConfigAPI,
    getOrgBindList,
    orgUserUnitSave,

    // 用户管理
    selectOrgReviewListPage,
    orgReviewExportData,
    orgUserUnitApproval,
    selectOrgAccountListPage,
    orgAccountExportData,
    orgAccountSave,
    getOrgUserData,
    getWebFeedbackList,
    webFeedbackExportData,
    webFeedbackSave,
    getWebFeedback,
    getWebReportList,
    webReportSave,
    getWebReport,
    webReportExportData,
    selectWebLoginLogListPage,
    // getUserOnlineTime,
    selectTimeListPage,

    // 资讯动态
    selectNewsListPage,
    newsDataSave,
    deleteNews,
    getNewsData,
    selectApproveNewListPage,
    newsDataApprove,

    // 官网首页管理
    selectBannerList,
    getBanner,
    bannerSave,
    selectFriendshipLinkListPage,

    // 帮助中心管理
    selectDocDirectoryTreeList,
    selectDocInfoListPageAPI,
    sysDocDirectorySaveAPI,
    sysDocInfoSaveAPI,
    getSysDocInfoAPI,
    transferSysDocAPI,
    copySysDocAPI,
    dragAdjustOrderAPI,
    selectDocFeebackListPageAPI,

    //课程资源
    selectResourcesListPageAPI,
    importResourcesByXlsxAPI,
    importResourcesTemplateAPI,
    courseSaveAPI,
    batchUpdateCourseStatusAPI,
    selectResourceDetailAPI,
    exportCourseDataAPI,
    exportOnlineCourseListAPI,
    downOnlineImportTemplateAPI,
    importOnlineByXlsxAPI,
}
