<template>
    <div id="app">
        <!-- 路由占位符 -->
        <router-view />
    </div>
</template>

<script>
// WebSocket封装方法
import { sendSock, createWebSocket, closeSock } from '@/utils/websocket'
import { mapState } from 'vuex'

export default {
    components: {},
    data() {
        return {}
    },
    computed: {
        ...mapState(['unReadMessageCnt', 'userInfo'])
    },
    watch: {
        userInfo: {
            handler(val, olVal) {
                if (val.id) {
                    this.init()
                }
            },
            deep: true
        }
    },
    created() {
        if (this.$cookies.get('token')) {
            this.init()
        }
    },
    beforeDestroy() {
        // 页面销毁时关闭ws。因为有可能ws连接接收数据尚未完成，用户就跳转了页面
        // 在需要主动关闭ws的地方都可以调用该方法
        closeSock()
    },
    methods: {
        init() {
            createWebSocket(this.global_callback)
        },
        // websocket的回调函数，msg表示收到的消息
        global_callback(res) {
            if (res.messageType == 0) return
            console.log('新消息', res)
            if (res.dataType == 'chatmsg') {
                // 互动消息
                this.$store.commit('renewChatMessageList', res.data)
                // 未读消息数量+1
                // this.$store.commit("renewUnReadMessageCnt", Number(this.unReadMessageCnt) + 1);
            } else if (res.dataType == 'aichat') {
                // AI问答消息
                if (res.data.finishReason == 'error') {
                    this.$message.warning(res.data.content)
                }
                // 更新会话id
                this.$store.commit('renewAiChatId', res.data.chatId)
                // 更新消息体
                this.$store.commit('renewAiMessageData', res.data)
            }
            // 服务端通知，当前机构咨询人
            if (res.messageType == 6) {
                switch (res.noticeCode) {
                    case '601':
                        // 聊天负责人通知
                        this.$store.commit('renewChatPersonObj', res.data)
                        break
                }
            }
        }
    }
}
</script>

<style lang="less">
#app {
}
</style>
