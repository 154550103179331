import { request } from './request'
import config from '@/config'

// ===== 易本书 =====

// 获取用户图书信息分页列表
function selectUserBookList(data) {
    return request({
        url: '/sys/userBook/selectListPage',
        method: 'POST',
        data: data
    })
}
// 图书上传审核
function userBookApprove(data) {
    return request({
        url: '/sys/userBook/userBookApprove',
        method: 'POST',
        data: data
    })
}
// 图书批量审核(ids-用户图书ID（英文逗号分隔）, approvalStatus-审核状态)
function userBookBatchApprove(data) {
    return request({
        url: '/sys/userBook/userBookBatchApprove',
        method: 'POST',
        data: data
    })
}
// 获取比邻书馆分页列表
function selectNearLibList(data) {
    return request({
        url: '/sys/nearLib/selectListPage',
        method: 'POST',
        data: data
    })
}
// 比邻书馆审核
function nearLibApprove(data) {
    return request({
        url: '/sys/nearLib/nearLibApprove',
        method: 'POST',
        data: data
    })
}
// 比邻书馆批量审核(ids-比邻书馆ID（英文逗号分隔）, approvalStatus-审核状态)
function nearLibBatchApprove(data) {
    return request({
        url: '/sys/nearLib/nearLibBatchApprove',
        method: 'POST',
        data: data
    })
}
// 获取比邻书馆成员申请记录分页列表
function selectNearLibUserApplyList(data) {
    return request({
        url: '/sys/nearLibUserApply/selectListPage',
        method: 'POST',
        data: data
    })
}
// 比邻书馆馆长申请审核
function nearLibDirectorApprove(data) {
    return request({
        url: '/sys/nearLibUserApply/nearLibDirectorApprove',
        method: 'POST',
        data: data
    })
}
// 比邻书馆馆长申请审核批量审核(ids-比邻书馆馆长申请记录ID（英文逗号分隔）, approvalStatus-审核状态)
function nearLibDirectorBatchApprove(data) {
    return request({
        url: '/sys/nearLibUserApply/nearLibDirectorBatchApprove',
        method: 'POST',
        data: data
    })
}
// 获取用户头衔信息分页列表
function selectUserTitleList(data) {
    return request({
        url: '/sys/userTitle/selectListPage',
        method: 'POST',
        data: data
    })
}
// 用户头衔信息保存(新增或修改)
function userTitleSave(data) {
    return request({
        url: '/sys/userTitle/userTitleSave',
        method: 'POST',
        data: data
    })
}
// 授予头衔(userIds-用户ID（英文逗号分隔）, titleType-头衔类型)
function batchGrantUserTitle(data) {
    return request({
        url: '/sys/userTitle/batchGrantUserTitle',
        method: 'POST',
        data: data
    })
}
// 获取积分商品信息分页列表
function selectScoreGoodsList(data) {
    return request({
        url: '/sys/scoreGoods/selectListPage',
        method: 'POST',
        data: data
    })
}
// 积分商品信息保存(新增或修改)
function scoreGoodsSave(data) {
    return request({
        url: '/sys/scoreGoods/scoreGoodsSave',
        method: 'POST',
        data: data
    })
}
// 根据ID查询积分商品信息
function getScoreGoods(data) {
    return request({
        url: '/sys/scoreGoods/getScoreGoods',
        method: 'POST',
        data: data
    })
}

// 获取积分商品订单分页列表
function selectScoreGoodsOrderList(data) {
    return request({
        url: '/sys/scoreGoodsOrder/selectListPage',
        method: 'POST',
        data: data
    })
}
// 积分商品兑换申请审核
function scoreGoodsExchangeApprove(data) {
    return request({
        url: '/sys/scoreGoodsOrder/scoreGoodsExchangeApprove',
        method: 'POST',
        data: data
    })
}
// 积分商品订单保存(新增或修改)
function scoreGoodsOrderSave(data) {
    return request({
        url: '/sys/scoreGoodsOrder/scoreGoodsOrderSave',
        method: 'POST',
        data: data
    })
}
// 获取图书馆入驻申请分页列表
function selectLibApplyListPageAPI(data) {
    return request({
        url: '/sys/libApply/selectListPage',
        method: 'POST',
        data: data
    })
}
// 获取图书馆入驻申请分页列表
function exLibApplySaveAPI(data) {
    return request({
        url: '/sys/libApply/libApplySave',
        method: 'POST',
        data: data
    })
}
export default {
    selectUserBookList,
    userBookApprove,
    userBookBatchApprove,
    selectNearLibList,
    nearLibApprove,
    nearLibBatchApprove,
    selectNearLibUserApplyList,
    nearLibDirectorApprove,
    nearLibDirectorBatchApprove,
    selectUserTitleList,
    userTitleSave,
    batchGrantUserTitle,
    selectScoreGoodsList,
    scoreGoodsSave,
    getScoreGoods,
    selectScoreGoodsOrderList,
    scoreGoodsExchangeApprove,
    scoreGoodsOrderSave,
    selectLibApplyListPageAPI,
    exLibApplySaveAPI,
}
