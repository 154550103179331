import home from './home.js'
import personal from './personal.js'
import other from './other.js'
import product from './product.js'
import activity from './activity.js'
import message from './message.js'
import system from './system.js'
import chat from './chat.js'
import data from './data.js'
import award from './award.js'
import exbook from './exbook.js'

var api = {}

api = Object.assign(api, home, personal, other, product, activity, message, system, chat, data, award, exbook)

export default api
