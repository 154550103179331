const { request } = require('./request')

// todo 奖证模块

// 奖证分页列表
function selectAwardsList (data) {
    return request({
        url: '/sys/actAwardsInfo/selectListPage',
        method: 'POST',
        data: data
    })
}
// 活动奖项信息保存(新增或修改或删除)
function actAwardsInfoSave (data) {
    return request({
        url: '/sys/actAwardsInfo/actAwardsInfoSave',
        method: 'POST',
        data: data
    })
}
// 根据ID查询活动奖项信息
function selectAwardListPage (data) {
    return request({
        url: '/sys/actAwardsInfo/getActAwardsInfo',
        method: 'POST',
        data: data
    })
}

// todo 获奖名单
// 获取获奖名单分页列表
function selectAwardList (data) {
    return request({
        url: '/sys/actAwardsList/selectListPage',
        method: 'POST',
        data: data
    })
}
// 导入获奖名单
function actAwardsListImport (data) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/sys/actAwardsList/actAwardsListImport',
        method: 'POST',
        data: data
    })
}
// 获奖名单模版下载
function downImportTemplate (data) {
    return request({
        url: '/sys/actAwardsList/downImportTemplate',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 获奖名单保存(新增或修改或删除)
function actAwardsListSave (data) {
    return request({
        url: '/sys/actAwardsList/actAwardsListSave',
        method: 'POST',
        data: data
    })
}
// 清除活动获奖信息 
function clearActAwardsList (data) {
    return request({
        url: '/sys/actAwardsList/clearActAwardsList',
        method: 'POST',
        data: data
    })
}
// 清除活动对象获奖信息  
function clearActObjectTypeAwardsList (data) {
    return request({
        url: '/sys/actAwardsList/clearActObjectTypeAwardsList',
        method: 'POST',
        data: data
    })
}
// 导出数据 
function exportAwardList (data) {
    return request({
        url: '/sys/actAwardsList/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

// todo 证书获得明细
// 获取证书获得明细分页列表
function selectCertList (data) {
    return request({
        url: '/sys/actCertDetail/selectListPage',
        method: 'POST',
        data: data
    })
}
// 根据ID查询证书获得明细
function getActCertDetail (data) {
    return request({
        url: '/sys/actCertDetail/getActCertDetail',
        method: 'POST',
        data: data
    })
}
// 证书获得明细保存(新增或修改)
function actCertDetailSave (data) {
    return request({
        url: '/sys/actCertDetail/actCertDetailSave',
        method: 'POST',
        data: data
    })
}

// todo 活动证书信息
// 获取活动证书信息分页列表
function selectCertInfoListPage (data) {
    return request({
        url: '/sys/actCertInfo/selectListPage',
        method: 'POST',
        data: data
    })
}
// 根据ID查询活动证书信息
function getActCertInfo (data) {
    return request({
        url: '/sys/actCertInfo/getActCertInfo',
        method: 'POST',
        data: data
    })
}
// 活动证书信息保存(新增或修改)
function actCertInfoSave (data) {
    return request({
        url: '/sys/actCertInfo/actCertInfoSave',
        method: 'POST',
        data: data
    })
}
module.exports = {
    selectAwardsList,
    actAwardsInfoSave,
    selectAwardListPage,
    selectAwardList,
    selectCertList,
    getActCertDetail,
    actCertDetailSave,
    selectCertInfoListPage,
    getActCertInfo,
    actCertInfoSave,
    actAwardsListImport,
    downImportTemplate,
    actAwardsListSave,
    clearActAwardsList,
    exportAwardList,
    clearActObjectTypeAwardsList
}
