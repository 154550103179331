/**
 * 正则表达式
 */

module.exports = {
    phoneReg: /^1[3456789]\d{9}$/, // 手机号
    telephoneReg: /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$|^400[016789]\d{6}$|^400-[016789]\d{2}-\d{4}$/, // 座机号码
    numberReg: /^[0-9]*$/, // 数字
    strongPswReg: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,18}$/, // 强密码
    emailReg: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    wechatReg: /^[a-zA-Z]([-_a-zA-Z0-9]{6,20})$/,
    qqReg: /^[1-9][0-9]{4,10}$/,
    internetURLReg: /[a-zA-z]+:\/\/[^\s]*/, // internetURL
    actURLReg: /^[A-Za-z0-9]+$/, // 由数字、26个英文字母组成的字符串
    httpUrlReg: /^http(s)?:\/\/[^\s]*/,
}