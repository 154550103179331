// 封装项目中用到的指令
import Vue from 'vue'

function adjustText(el, { value }) {
    Vue.nextTick(() => {
        if (!value || !value.length) return
        for (let i = 0; i <= value.length; i++) {
            el.innerHTML = value.substring(0, i)
            if (el.offsetHeight < el.scrollHeight) {
                el.style.overflow = 'hidden'
                el.innerHTML = value.substring(0, i - 7) + '...' + value.substring(value.length - 4)
                break
            }
        }
    })
}
Vue.directive('text-overflow', {
    inserted: adjustText,
    // 指令所在组件的 VNode 及其子 VNode 全部更新后调用
    update: adjustText
})

// 按钮点击时失去焦点
Vue.directive('button-blur-on-click', {
    inserted(el) {
        el.addEventListener('click', () => {
            el.blur()
        })
    },
    unbind(el) {
        el.removeEventListener('click', () => {
            el.blur()
        })
    }
})
// 移除aria-hidden，单选框切换的谷歌兼容问题
Vue.directive('removeAriaHidden', {
    bind(el, binding) {
        const ariaEls = el.querySelectorAll('.el-radio__original')
        ariaEls.forEach((item) => {
            item.removeAttribute('aria-hidden')
        })
    }
})
